import React, { Component } from "react";
import $ from "jquery";
// Utils
import * as API from "../../utils/api";

class ChoosePasswordForm extends Component {
  state = {
    input: {
      password: {
        value: "",
        hasError: false,
      },
      confirmPassword: {
        value: "",
        hasError: false,
      },
    },
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { user } = this.props;
    if (this.validateForm()) {
      // $('#sbmt-new-pwd').replaceWith('<div class="spinner"></div>');
      // const authToken = this.props.token;
      // const userID = this.props.userID;
      let Payload = {
        user_info: {
          email: this.props.onboardingUserData.email,
          password: this.state.input.password.value,
          confirm_password: this.state.input.confirmPassword.value,
        },
      };
      this.props.handleStateChange({
        password: this.state.input.password.value,
      });
      API.createStandaloneOrg(Payload).then((data) => {
        console.log(data);
        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          data.status_code === 200
        ) {
          this.props.handleSubmit();
          this.props.handleStateChange({
            password: this.state.input.password.value,
            organization_key: data.org_key,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      });
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    if (newInputState.password.value === "") {
      newInputState.password.hasError = true;
      errorsArePresent = true;
    }

    if (newInputState.confirmPassword.value === "") {
      newInputState.confirmPassword.hasError = true;
      errorsArePresent = true;
    } else if (
      newInputState.confirmPassword.value !== newInputState.password.value
    ) {
      newInputState.confirmPassword.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  clearFormInput = () => {
    this.setState((state) => ({
      ...state,
      input: {
        password: {
          value: "",
          hasError: false,
        },
        confirmPassword: {
          value: "",
          hasError: false,
        },
      },
    }));
  };

  renderErrorMessage(input) {
    let message =
      input === "password"
        ? "Please enter a password"
        : "Please make sure passwords match";

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { input } = this.state;

    return (
      <form
        className="sessions-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <div
          className={`input-container${
            input.password.hasError ? " error" : ""
          }`}
        >
          <label>Password</label>
          <input
            name="password"
            type="password"
            value={input.password.value}
            onChange={(event) =>
              this.handleInputChange({
                password: { value: event.target.value, hasError: false },
              })
            }
          />
          {input.password.hasError ? this.renderErrorMessage("password") : null}
        </div>

        <div
          className={`input-container${
            input.confirmPassword.hasError ? " error" : ""
          }`}
        >
          <label>Confirm Password</label>
          <input
            name="confirmPassword"
            type="password"
            value={input.confirmPassword.value}
            onChange={(event) =>
              this.handleInputChange({
                confirmPassword: { value: event.target.value, hasError: false },
              })
            }
          />
          {input.confirmPassword.hasError
            ? this.renderErrorMessage("confirmPassword")
            : null}
        </div>
        <input
          id="sbmt-new-pwd"
          className="cta mg-button"
          type="submit"
          value="Submit New Password"
        />
      </form>
    );
  }
}

export default ChoosePasswordForm;
