import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import ChoosePasswordForm from "../../components/Sessions/ChoosePasswordForm";
import Logo from "../../components/Logo";

import { getZohoPagesense } from "../../utils/helpers";

class ChoosePassword extends Component {
  state = {
    showChoosePasswordForm: true,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ChoosePassword");
  }

  handleNewPasswordFormSubmit = () => {
    this.setState((state) => ({
      showChoosePasswordForm: false,
    }));
  };

  render() {
    let { appName, history } = this.props;
    let { showChoosePasswordForm } = this.state;
    console.log("this.props", this.props);
    window.history.pushState({}, "Change Your Password", "/choose-password");

    return (
      <div
        className="sessions fill-screen mg-session"
        style={{ position: "relative" }}
      >
        <Helmet>
          <title>{appName} | Choose Password</title>
        </Helmet>
        <SessionsModal>
          <div
            style={{
              position: "absolute",
              top: "5%",
              left: "5%",
            }}
          >
            <Link to="/launch" className="mg-back-button">
              &lt; &lt; &lt;Back
            </Link>
          </div>
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              <span>Create New Password</span>
            </h1>
            {showChoosePasswordForm && (
              <ChoosePasswordForm
                userID={this.props.userID}
                token={this.props.token}
                user={this.props.user}
                updateModalState={this.props.updateModalState}
                handleSubmit={this.handleNewPasswordFormSubmit}
                handleStateChange={this.props.handleStateChange}
                onboardingUserData={this.props.onboardingUserData}
              />
            )}
            {!showChoosePasswordForm && (
              <div>
                <p>Your password has been updated successfully!</p>
                <p
                  onClick={() => {
                    history.push("/sso_dashboard");
                  }}
                  className="cta mg-button"
                >
                  Continue to Onboarding process
                </p>
              </div>
            )}
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default ChoosePassword;
