import React from "react";
export function getIcon(choice) {
  let icon;
  let input = choice;
  switch (input) {
    case "password-lock-icon":
      return (
        <>
          <svg
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Icon/Solid/lock-closed">
              <path
                id="Icon"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 9V7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7V9C16.1046 9 17 9.89543 17 11V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V11C3 9.89543 3.89543 9 5 9ZM13 7V9H7V7C7 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7Z"
                fill="#999999"
              />
            </g>
          </svg>
        </>
      );

    case "network-domain-icon":
      return (
        <>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Earth 1" clip-path="url(#clip0_103_256)">
              <g id="Group">
                <path
                  id="Vector"
                  d="M0 7.53125H3.32456C3.35534 6.35766 3.49884 5.25203 3.73816 4.25H0.972594C0.443281 5.23797 0.0691875 6.35125 0 7.53125ZM3.32456 8.46875H0C0.0691875 9.64875 0.443281 10.762 0.972594 11.75H3.73819C3.49888 10.748 3.35534 9.64234 3.32456 8.46875ZM7.53125 7.53125V4.25H4.70747C4.45091 5.243 4.29553 6.35869 4.26156 7.53125H7.53125ZM7.53125 3.3125V0C6.50478 0.286687 5.59553 1.59822 4.99547 3.3125H7.53125ZM7.53125 16V12.6875H4.99547C5.59553 14.4018 6.50478 15.7133 7.53125 16ZM8.46875 0V3.3125H11.0045C10.4045 1.59822 9.49522 0.286687 8.46875 0ZM11.7384 7.53125C11.7044 6.35869 11.5491 5.243 11.2925 4.25H8.46875V7.53125H11.7384ZM7.53125 8.46875H4.26156C4.29556 9.64137 4.45091 10.757 4.70747 11.75H7.53125V8.46875ZM12.0003 3.3125H14.43C13.4597 1.98522 12.0938 0.863906 10.5024 0.335719C11.114 1.05772 11.6255 2.14344 12.0003 3.3125ZM3.99966 12.6875H1.56997C2.54025 14.0148 3.90622 15.1361 5.49759 15.6643C4.886 14.9423 4.37447 13.8566 3.99966 12.6875ZM3.99966 3.3125C4.37444 2.14344 4.886 1.05772 5.49756 0.335719C3.90622 0.863906 2.54025 1.98522 1.56997 3.3125H3.99966ZM12.0003 12.6875C11.6256 13.8566 11.114 14.9423 10.5024 15.6643C12.0938 15.1361 13.4598 14.0148 14.4301 12.6875H12.0003ZM8.46875 8.46875V11.75H11.2925C11.5491 10.757 11.7045 9.64137 11.7384 8.46875H8.46875ZM12.2618 4.25C12.5011 5.25203 12.6447 6.35766 12.6754 7.53125H16C15.9308 6.35125 15.5567 5.23797 15.0274 4.25H12.2618ZM8.46875 12.6875V16C9.49522 15.7133 10.4045 14.4018 11.0045 12.6875H8.46875ZM16 8.46875H12.6754C12.6447 9.64234 12.5012 10.748 12.2618 11.75H15.0274C15.5567 10.762 15.9308 9.64875 16 8.46875Z"
                  fill="#999999"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_103_256">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </>
      );

    case "closed-eye":
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M1.50004 1.5L4.49101 4.49097M16.5 16.5L13.5094 13.5093M10.5624 14.6872C10.0565 14.7831 9.53431 14.8333 9.00042 14.8333C5.26905 14.8333 2.11046 12.3809 1.04858 8.99997C1.33767 8.07958 1.78213 7.22801 2.35124 6.47598M7.23227 7.23223C7.68468 6.77982 8.30968 6.5 9.00004 6.5C10.3808 6.5 11.5 7.61929 11.5 9C11.5 9.69036 11.2202 10.3154 10.7678 10.7678M7.23227 7.23223L10.7678 10.7678M7.23227 7.23223L4.49101 4.49097M10.7678 10.7678L4.49101 4.49097M10.7678 10.7678L13.5094 13.5093M4.49101 4.49097C5.79086 3.65295 7.33884 3.16667 9.00041 3.16667C12.7318 3.16667 15.8904 5.61909 16.9522 9.00003C16.3632 10.8756 15.1288 12.4654 13.5094 13.5093"
              stroke="#4D4D4D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </>
      );

    case "opened-eye":
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
          >
            <g id="Icon">
              <path
                d="M11.5 7.00002C11.5 8.38073 10.3807 9.50002 8.99998 9.50002C7.61927 9.50002 6.49998 8.38073 6.49998 7.00002C6.49998 5.61931 7.61927 4.50002 8.99998 4.50002C10.3807 4.50002 11.5 5.61931 11.5 7.00002Z"
                stroke="#4D4D4D"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.04852 6.99999C2.11042 3.61908 5.26899 1.16669 9.00035 1.16669C12.7317 1.16669 15.8903 3.61912 16.9522 7.00005C15.8903 10.381 12.7317 12.8334 9.00036 12.8334C5.26898 12.8334 2.1104 10.3809 1.04852 6.99999Z"
                stroke="#4D4D4D"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </>
      );

    case "email-icon-block":
      return (
        <>
          <svg
            width="16"
            height="22"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.00333343 2.76014L7.99995 6.75845L15.9967 2.76009C15.9363 1.70974 15.0655 0.876587 14 0.876587H2C0.934518 0.876587 0.0636347 1.70977 0.00333343 2.76014Z"
              fill="#999999"
            />
            <path
              d="M16 4.99449L7.99995 8.99452L0 4.99454V10.8766C0 11.9812 0.895431 12.8766 2 12.8766H14C15.1046 12.8766 16 11.9812 16 10.8766V4.99449Z"
              fill="#999999"
            />
          </svg>
        </>
      );

    case "check-box-open":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke="#B7B7B7" />
        </svg>
      );

    case "check-box-closed":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_161_1565)">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#09B795" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8636 8.13633C18.1565 8.42922 18.1565 8.90409 17.8636 9.19699L11.1969 15.8637C10.904 16.1565 10.4291 16.1565 10.1363 15.8637L6.80292 12.5303C6.51003 12.2374 6.51003 11.7626 6.80292 11.4697C7.09582 11.1768 7.57069 11.1768 7.86358 11.4697L10.6666 14.2727L16.8029 8.13633C17.0958 7.84343 17.5707 7.84343 17.8636 8.13633Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_161_1565">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
}

export default getIcon;
