import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
// import ClientsNav from "../../../components/Clients/ClientsNav";
import MGLogoIcon from "../../../assets/icons/MG-logo_icon.png";
import * as API from "../../../utils/api";
import { errorMessage } from "../../../utils/helpers";
import MakeGoodEULA from "../../../screens/EndUserLicenseAgreement";

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff",
    opacity: 0.95,
  },
};

class ClientsSSODashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSavedSubdomain: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      user_auth_token: null,
      user_id: null,
      eula_accepted_date: false,
    };
  }

  componentDidMount() {
    var me = this;
    me.ssoLoginMethod();
  }

  dateIsValid = (date) => {
    if (date && date != "") {
      return new Date(date) instanceof Date && !isNaN(new Date(date));
    } else {
      return false;
    }
  };

  ssoLoginMethod = () => {
    let { organization_key, email, password } = this.props.onboardingUserData;
    let { history } = this.props;
    let userCandidate = {
      email: email,
      organization_key: organization_key,
      password: password,
    };
    this.setState({ isLoading: true });
    API.setOauthSSOObj(userCandidate);
    API.ssoLogin(userCandidate).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (!data.errors) {
          const user = {
            first_name: data.first_name,
            last_name: data.last_name,
            user_id: data.id,
            user_auth_token: data.auth_token,
            admin: data.admin,
            is_employee: data.is_employee,
            super_admin: data.super_admin,
            onboarding_complete: data.accepted_on ? true : false,
            is_onboarding_completed: data.accepted_on ? true : false,
            client_admin: data.client_admin,
            verified: data.verified,
            verified_at: data.verified_at,
            email: data.email,
            user_hash: data.user_hash,
            intercom_id: data.intercom_id,
            organization_key: organization_key,
          };
          this.setState({
            user_auth_token: data.auth_token,
            user_id: data.id,
            eula_accepted_date: this.dateIsValid(data.accepted_on),
          });
          if (this.dateIsValid(data.accepted_on)) {
            this.props.setEULAtrue();
          }
          this.props.updateUserLogin(user);
        } else {
          this.setState((state) => ({
            ...state,
            showFlash: true,
            accessError: errorMessage,
            errorMsg: data.errors,
          }));
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
              (data.message && data.message == "Access denied."
                ? 401
                : 500) ||
              500
              : 500,
          },
        }));
      }
      this.setState({ isLoading: false });
    });
  };

  nextClick = () => {
    let { history, onboardingUserData } = this.props;
    history.push(`/enter-user-details`, {
      onboardingUserData: onboardingUserData,
    });
  };

  render() {
    const { appName, user } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <>
        {isLoading ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) :
          <div
            className={`app-portal-canvas consumer-portal canvas-back`}
            style={this.props.backgroundStyle}
          >
            <MakeGoodEULA
              isEULAtrue={this.props.isEULAtrue}
              setEULAtrue={this.props.setEULAtrue}
              updateModalState={this.props.updateModalState}
              user={{
                user_auth_token: this.state.user_auth_token,
                user_id: this.state.user_id,
                eula_accepted_date: this.state.eula_accepted_date,
              }}
            />
          </div>
        }
      </>
    );
  }
}
export default ClientsSSODashboard;
