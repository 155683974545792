//import companyData from "../assets/styles/theme.json";
import OAuth2 from "fetch-mw-oauth2";
import Cookies from "universal-cookie";
import Emitter from "../utils/event-emitter";
// let api = "http://0548-137-59-64-76.ngrok.io";
let api = process.env.REACT_APP_API_URL;
let clientID = process.env.REACT_APP_OAUTH_CLIENT_ID;
let clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;

let oauth2;
const cookies = new Cookies();

export const getBaseUrl = () => {
  return window.location.origin;
  // return "https://mgstage.accountpayonline.com";
};

const setHeaders = (user, useAuth, useJSON) => {
  let headerObj = {};
  if (useAuth) {
    headerObj["Authorization"] = user.user_auth_token;
  }

  if (useJSON) {
    headerObj["Content-Type"] = "application/json";
  }
  if (user && user.organization_key) {
    headerObj["Organization-Key"] = user.organization_key;
  } else {
    headerObj["Organization-Key"] = "";
  }
  return headerObj;
};

export const getRealmIdFromQB = (realmid) =>
  fetch(`${api}/launch?realmid=${realmid}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientMandrillRequest = (payload) =>
  fetch(`${api}/client/mandrill/leadRequest`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const signInWithIntuit = (url) =>
  fetch(`${api}/qb_sso?return_url=${url}`, {
    method: "GET",
    headers: setHeaders(null, false, false),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const checkEmailRegistration = (email) =>
  fetch(`${api}/check_email?email=${email}`, {
    method: "GET",
    headers: setHeaders(null, false, false),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createStandaloneOrg = (payload) =>
  fetch(`${api}/client/create_standalone_org`, {
    method: "POST",
    // headers: setHeaders(null, false, false),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getLogoColorsForPayment = (key) =>
  fetch(`${api}/logoColors?org_url=${getBaseUrl()}`, {
    method: "GET",
    // headers: setHeaders(null, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const ssoLogin = (user) =>
  fetch(`${api}/sso_session`, {
    method: "POST",
    headers: setHeaders(user, false, true),
    body: JSON.stringify({
      session: {
        email: user.email,
        password: user.password,
        organization_key: user.organization_key,
      },
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      if (error && error.message != "Failed to fetch") {
        return { errors: error.message };
      }
    });
export const setOauthSSOObj = (payload) => {
  oauth2 = new OAuth2({
    grantType: "password",
    clientId: clientID,
    clientSecret: clientSecret,
    username: payload.email,
    password: payload.password,
    tokenEndpoint: `${api}/oauth/token?organization_key=${
      payload.organization_key
    }&username=${payload.email}&password=${encodeURIComponent(
      payload.password
    )}`,
    onTokenUpdate: (token) => {
      cookies.remove("accessToken", { path: "/", secure: true });
      cookies.remove("refreshToken", { path: "/", secure: true });
      cookies.set("accessToken", token.accessToken, {
        path: "/",
        secure: true,
      });
      cookies.set("refreshToken", token.refreshToken, {
        path: "/",
        secure: true,
      });
    },
  });
};
export const usersAgreement = (userID, user) =>
  oauth2
    .fetch(`${api}/users/${userID}/agreement`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ accepted: true }),
    })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const saveSubdomain = (user, payload) =>
  oauth2
    .fetch(`${api}/save_subdomain`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
