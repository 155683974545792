import React, { Component } from "react";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

class ModalConductor extends Component {
  render() {
    let { currentModal, stripePubKey } = this.props;

    switch (currentModal) {
      case "ERROR":
        return <ErrorModal {...this.props} />;
      case "SUCCESS":
        return <SuccessModal {...this.props} />;
      default:
        return null;
    }
  }
}

export default ModalConductor;
