import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyData from "./assets/styles/theme.json";
import ModalConductor from "./components/App/Modals/ModalConductor";
// Import Components
import SVGSprite from "./components/SVGSprite";
import QbLaunch from "./screens/QuickBook/QbLaunch";
import QbDisconnect from "./screens/QuickBook/QbDisconnect";
import Favicon from "./assets/icons/favicon.ico";
import ChoosePassword from "./screens/Sessions/ChoosePassword";
// Utils
import { googleAnalyticId } from "./utils/helpers";
import Emitter from "./utils/event-emitter";
import BadRequest from "./components/error-pages/bad-request";
import ClientsSSODashboard from "./screens/ClientsPortal/sso/ClientsSSODashboard";
import CreateSubDomain from "./screens/ClientsPortal/sso/CreateSubDomain";
import onboardingBackgroundImage from "./assets/images/background_onboarding.png"

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  // App will manage the state of global elements such as the user auth status
  state = {
    back_Path: "",
    user: {},
    isLoggedIn: false,
    showModal: false,
    currentModal: null,
    optionalModalProps: null,
    prevPath: null,
    confirmOnboardingPhoneNumber: false,
    hasAnyError: false,
    appName: "", //company_name
    clientName: "",
    appEmail: "https://www.debnow.com",
    heapEmail: null,
    heapAppId: null,
    intercomAppId: null,
    stripePubKey: null,
    isEULAtrue: false,
    onboardingUserData: {},
  };
  backgroundStyle = {
    backgroundImage:`url(${onboardingBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width:"100vw",
    height:"100vh",
  }
  setEULAtrue = () => {
    this.setState({ isEULAtrue: true });
  };
  handleStateChange = (newData) => {
    this.setState((state) => ({
      ...state,
      onboardingUserData: {
        ...state.onboardingUserData,
        ...newData,
      },
    }));
  };
  setCookiesData = (key,value) => {
    const cookies = new Cookies();
    cookies.set(key, value, {
      path: "/",
      secure: true,
    });
  }
  getCookiesData = (name) => {
    const cookies = new Cookies();
    return cookies.get(name, { path: "/", secure: true })
  }
  updateUserLogin = (newData) => {
    this.setState((state) => ({
      ...state,
      user: newData,
    }));
  };
  handleModalChange = (showModalState, modalType, optionalModalProps) => {
    this.setState((state) => ({
      ...state,
      showModal: showModalState,
      currentModal: modalType,
      optionalModalProps,
    }));
  };

  hideAndResetModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
      currentModal: null,
      optionalModalProps: null,
    }));
  };

  intializeGA = () => {
    ReactGA.initialize(googleAnalyticId);
  };

  setUserData = (data) => {
    this.setState({ userData: data });
  };

  render() {
    let { showModal, currentModal, stripePubKey } = this.state;
    //const appName = appNameText;
    const modal = showModal ? (
      <ModalConductor
        user={this.state.user}
        stripePubKey={stripePubKey}
        appName={this.state.appName}
        appEmail={this.state.appEmail}
        currentModal={this.state.currentModal}
        hideModal={this.hideAndResetModal}
        updateModalState={this.handleModalChange}
        optionalProps={this.state.optionalModalProps}
        handleStateChange={this.handleStateChange}
        onboardingUserData={this.state.onboardingUserData}
      />
    ) : null;

    return (
      <div id="app">
        <script>{this.intializeGA()}</script>
        <Helmet>
          <link rel="shortcut icon" href={Favicon} />
        </Helmet>
        <SVGSprite />
        <Switch>
          <Route
            exact
            path="/launch"
            render={(props) => (
              <QbLaunch
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                handleStateChange={this.handleStateChange}
                setCookiesData={this.setCookiesData}
                onboardingUserData={this.state.onboardingUserData}
                getCookiesData={this.getCookiesData}
                backgroundStyle={this.backgroundStyle}
              />
            )}
          />
          <Route
            exact
            path="/qb-disconnect"
            render={(props) => (
              <QbDisconnect
                {...props}
                appName={this.state.appName}
                updateModalState={this.handleModalChange}
                location={props.location.pathname}
                handleStateChange={this.handleStateChange}
                setCookiesData={this.setCookiesData}
                onboardingUserData={this.state.onboardingUserData}
                getCookiesData={this.getCookiesData}
                backgroundStyle={this.backgroundStyle}
              />
            )}
          />
          <Route
            path="/choose-password"
            render={(props) => (
              <ChoosePassword
                {...props}
                appName={this.state.appName}
                updateModalState={this.handleModalChange}
                location={props.location.pathname}
                handleStateChange={this.handleStateChange}
                setCookiesData={this.setCookiesData}
                onboardingUserData={this.state.onboardingUserData}
                getCookiesData={this.getCookiesData}
                backgroundStyle={this.backgroundStyle}
              />
            )}
          />
          <Route
            exact
            path="/sso_dashboard"
            render={(props) => (
              <ClientsSSODashboard
                {...props}
                user={this.state.user}
                isEULAtrue={this.state.isEULAtrue}
                setEULAtrue={this.setEULAtrue}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.updateUserLogin}
                handleStateChange={this.handleStateChange}
                setCookiesData={this.setCookiesData}
                onboardingUserData={this.state.onboardingUserData}
                getCookiesData={this.getCookiesData}
                backgroundStyle={this.backgroundStyle}
              />
            )}
          />
          <Route
            exact
            path="/enter-user-details"
            render={(props) => (
              <CreateSubDomain
                {...props}
                user={this.state.user}
                isEULAtrue={this.state.isEULAtrue}
                setEULAtrue={this.setEULAtrue}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.updateUserLogin}
                handleStateChange={this.handleStateChange}
                setCookiesData={this.setCookiesData}
                onboardingUserData={this.state.onboardingUserData}
                getCookiesData={this.getCookiesData}
                backgroundStyle={this.backgroundStyle}
              />
            )}
          />
          <Redirect exact from="/" to="/launch" push />
          <Route component={BadRequest} />
        </Switch>

        {showModal ? modal : ""}

        {showModal &&
        (!currentModal === "SUCCESS" || !currentModal === "ERROR") ? (
          <div className="curtain"></div>
        ) : (
          ""
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
      </div>
    );
  }
}

export default withCookies(App);
