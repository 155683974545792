import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../assets/icons/MG-logo.png";

class QbDisconnect extends Component {
  constructor(props) {
    super(props);
  }

  onContinueClick = () => {
    if (`${window.location.origin}`.includes("accountpayonline")) {
      window.location.href = "https://app.accountpayonline.com/launch";
    } else if (window.location.origin.includes("makegoodapp")) {
      window.location.href = "https://app.makegoodapp.com/launch";
    }
  };

  render() {
    const { heapEmail, heapAppId, appName } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Success</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className="sessions-modal-logo-container centerd-content">
            <img src={Logo} />
          </div>
          <div className="sessions-modal-content" 
          style={{marginTop:"30px",fontSize:"16px"}}>
            <p className="qb-msg-text">
              Your QuickBooks account has been disconnected successfully.
            </p>
          </div>
          <div className="modal-actions qb-btns">
            <a
              className="cta"
              onClick={() => {
                this.onContinueClick();
              }}
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default QbDisconnect;
