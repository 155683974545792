import React from "react";
import Modal from "../../components/App/Modals/Modal";
import MGLogoIcon from "../../assets/icons/MG-logo.png";
import * as API from "../../utils/api";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import signInWithIntuit from "../../assets/images/landing/Sign_in_transparent_btn_tall_default.png";
import signInWithIntuitHover from "../../assets/images/landing/Sign_in_transparent_btn_tall_hover.png";
import { Redirect } from "react-router-dom";
import { getIcon } from "../../assets/icons/svg_icons_list.js";
import {
  validateSubDomain,
  validateEmailCheckAndErrorMsg,
} from "../../utils/helpers";
class QbLaunch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        domainName: {
          value: "",
          domain: "",
          errorMsg: "",
          hasError: false,
          animate: false,
        },
        email: {
          value: "",
          errorMsg: "",
          hasError: false,
          animate: false,
        },
      },
      websiteURL: "",
      checkIsNewUser: false,
      mgdomainError: false,
      mgdomainErrorandConfirmation: false,
      mgdomain2Error: false,
      isLoader: false,
      checkActiveTab: "Email",
      signInWithIntuitImg: "",
      isReadyToSubmit: false,
    };
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.getQbLaunchData();
    this.setState({
      signInWithIntuitImg: signInWithIntuit,
    });
  }

  signInWithIntuit = (url) => {
    this.setState({ isLoader: true });
    API.signInWithIntuit(url).then((data) => {
      if ((typeof data !== "undefined" || data !== null) && !data.errors) {
        window.open(data.data, "_self");
      }
    });
    this.setState({ isLoader: false });
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    this.props.handleStateChange(newPartialInput);
  }

  animateLabel = (type, value) => {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        [type]: {
          ...state.input[type],
          animate: value !== "",
        },
      },
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { history } = this.props;
    let email = this.state.input.email.value;
    let check = validateEmailCheckAndErrorMsg(email);
    if (check.flag) {
      API.checkEmailRegistration(email).then((data) => {
        if (data && data.status_code == 200) {
          this.props.handleStateChange({ websiteURL: data.website_url });
          this.setState((state) => ({
            ...state,
            websiteURL: data.website_url,
            checkIsNewUser: false,
          }));
          window.open(`${data.website_url}/login?user=${email}`, "_self");
        } else {
          this.props.handleStateChange({
            websiteURL: "",
            checkIsNewUser: true,
            email: email,
          });
          this.props.setCookiesData("email", email);
          this.props.setCookiesData("checkIsNewUser", true);
          this.setState((state) => ({
            ...state,
            websiteURL: "",
            checkIsNewUser: true,
          }));
          history.push("/enter-user-details");
        }
      });
    }
  };

  handleContinue = () => {
    if (this.state.input.domainName.value === "") {
      this.props.updateModalState(true, "ERROR", {
        message: "Please enter a valid domain name to continue.",
      });
    } else {
      window.open(
        `https://${
          this.state.input.domainName.value
        }${this.validateHostUrlText()}`
      );
    }
  };

  async getQbLaunchData() {
    this.setState({ isLoader: true });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let realmid = urlParams.get("realmid");

    // realmid = "4620816365179202380";
    if (realmid) {
      await API.getRealmIdFromQB(realmid).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          data.status_code == 200
        ) {
          let result = data.data.split(".")[0].slice(8);
          let result2 = data.data.split(".")[1] + "." + data.data.split(".")[2];
          this.setState((state) => ({
            ...state,
            domainName: { value: result, domain: result2 },
          }));
          this.signInWithIntuit(`https://${result}.${result2}`);
        } else {
          //open error modal
          this.props.updateModalState(true, "ERROR", {
            message: data.message
              ? data.message
              : "Current Quickbook Company is not assosciated with MakeGood.",
          });
        }
      });
    }
    this.setState({ isLoader: false });
    if (this.state.input.domainName.domain == "") {
      this.setState({ mgdomain2Error: true });
    }
  }

  validateHostUrl = () => {
    if (`${window.location.origin}`.includes("accountpayonline")) {
      return "https://mgstage.accountpayonline.com";
    } else if (window.location.origin.includes("makegoodapp")) {
      return "https://makegood.makegoodapp.com";
    } else {
      return "https://mgstage.accountpayonline.com";
    }
  };

  validateHostUrlText = () => {
    if (`${window.location.origin}`.includes("accountpayonline")) {
      return ".accountpayonline.com";
    } else if (window.location.origin.includes("makegoodapp")) {
      return ".makegoodapp.com";
    } else {
      return ".accountpayonline.com";
    }
  };

  validateHostAndIntercommId = () => {
    if (`${window.location.origin}`.includes("accountpayonline")) {
      return "mmxnsa15";
    } else if (window.location.origin.includes("makegoodapp")) {
      return "mpig6e59";
    } else {
      return "mmxnsa15";
    }
  };

  changeTabInput = (type) => {
    if (type === "Email") {
      this.setState((state) => ({
        ...state,
        checkActiveTab: "Email",
        input: {
          ...state.input,
          domainName: {
            value: "",
            domain: "",
            errorMsg: "",
            hasError: false,
            animate: false,
          },
        },
      }));
    } else if (type === "Domain") {
      this.setState((state) => ({
        ...state,
        checkActiveTab: "Domain",
        input: {
          ...state.input,
          email: {
            value: "",
            errorMsg: "",
            hasError: false,
            animate: false,
          },
        },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        checkActiveTab: "Email",
      }));
    }
  };

  render() {
    const { appName, user } = this.props;
    const { isLoading, error, input, checkActiveTab } = this.state;
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: this.validateHostAndIntercommId(),
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/mmxnsa15'
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = `https://widget.intercom.io/widget/mpig6e59`;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
    return (
      <>
        <div className='app-portal'>
          {isLoading && <AppCanvasLoader />}
          <div
            className={`app-portal-canvas consumer-portal canvas-back`}
            style={this.props.backgroundStyle}
          >
            <div className='wrap sso-dashboard-cls he-hed'>
              <div className='company-logo-center'>
                <img
                  className='companyLogoDiv-consumer-view'
                  src={MGLogoIcon}
                />
              </div>
              <div className='user-details-content'>
                <div className='offer-section'>
                  <p className='offer-title'>Limited Time Offer</p>
                  <p style={{ margin: "0px" }}>
                    Start your 30 day trial by 7/1 and get the first 3 months
                    free!
                  </p>
                </div>
                <h4 className='user-details-heading'>Welcome!</h4>
                {checkActiveTab === "Email" ? (
                  <p className='domain-sub-label-cls'>
                    Get Started Now With Your Email
                  </p>
                ) : (
                  <p className='domain-sub-label-cls'>Sign In With Domain</p>
                )}
                <p>
                  For new users, kindly sign in using your email ID. For
                  existing users, sign in with your email address or use the
                  domain name associated with you MakeGood account.
                </p>
                <div>
                  <div className='tab-signin-options-bar'>
                    <div className='tab-signin-options'>
                      <p
                        className={`${
                          checkActiveTab === "Email" ? "active" : "in-active"
                        }`}
                        onClick={() => this.changeTabInput("Email")}
                      >
                        Email
                      </p>
                      <p
                        className={`${
                          checkActiveTab === "Domain" ? "active" : "in-active"
                        }`}
                        onClick={() => this.changeTabInput("Domain")}
                      >
                        Domain
                      </p>
                    </div>
                  </div>
                  {checkActiveTab === "Email" ? (
                    <div
                      className={`user-details-input-textField`}
                      style={{ margin: "0px" }}
                    >
                      <div className='email-signup'>
                        {getIcon("email-icon-block")}
                      </div>
                      {input.email.animate && <label>Email</label>}
                      <input
                        id='email'
                        placeholder={!input.email.animate ? "Email" : ""}
                        type='text'
                        value={input.email.value}
                        autoComplete='email'
                        onBlur={(event) =>
                          this.animateLabel("email", event.target.value)
                        }
                        onFocus={(event) =>
                          this.animateLabel("email", event.target.value)
                        }
                        onChange={(event) => {
                          {
                            let check = validateEmailCheckAndErrorMsg(
                              event.target.value
                            );
                            this.handleInputChange({
                              email: {
                                value: event.target.value,
                                hasError: !check.flag,
                                errorMsg: check.msg,
                                animate: true,
                              },
                            });
                            this.setState((state) => ({
                              ...state,
                              isReadyToSubmit: check.flag ? true : false,
                            }));
                          }
                        }}
                      />
                      {input.email.hasError ? (
                        <p className='input-details-error-message'>
                          {input.email.errorMsg}
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className='user-domainName-container'>
                        <div className='user-domainName'>
                          {getIcon("network-domain-icon")}
                        </div>
                        {input.domainName.animate && <label>Domain Name</label>}
                        <input
                          className='multi-input-field-input sub-domain-input mbwt'
                          id='new-user-domainName'
                          placeholder={
                            !input.domainName.animate ? "Domain Name" : ""
                          }
                          type='text'
                          value={input.domainName.value}
                          onBlur={(event) =>
                            this.animateLabel("domainName", event.target.value)
                          }
                          onFocus={(event) =>
                            this.animateLabel("domainName", event.target.value)
                          }
                          onChange={(event) => {
                            {
                              let check = validateSubDomain(event.target.value);
                              this.handleInputChange({
                                domainName: {
                                  value: event.target.value,
                                  hasError: !check.flag,
                                  errorMsg: check.msg,
                                  animate: true,
                                },
                              });
                              this.setState((state) => ({
                                ...state,
                                isReadyToSubmit: check.flag ? true : false,
                              }));
                            }
                          }}
                        />
                        <p className='mbdomain'>
                          {process.env.REACT_APP_STAGE === "production"
                            ? ".makegoodapp.com"
                            : ".accountpayonline.com"}
                        </p>
                      </div>
                      {input.domainName.hasError
                        ? input.domainName.errorMsg.map((msg, index) => (
                            <p key={index} className='input-error-message'>
                              {msg}
                            </p>
                          ))
                        : null}
                    </>
                  )}
                  <div className={"submit-user-details"}>
                    <button
                      type='submit'
                      className={`${
                        this.state.isReadyToSubmit
                          ? "button-background1"
                          : "button-background2"
                      }`}
                      onClick={(event) => {
                        checkActiveTab === "Email"
                          ? this.onSubmit(event)
                          : this.handleContinue();
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                  <div className='content-seprator'>
                    <span className='seprator'></span>
                    <span className='text'>OR</span>
                    <span className='seprator'></span>
                  </div>
                  <div>
                    <p
                      style={{ margin: "0px" }}
                      className='domain-sub-label-cls'
                    >
                      Try MakeGood For Free
                    </p>
                    <p style={{ margin: "0px" }}>
                      (Get started with your QuickBooks Online account.)
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "1.5rem",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className='sign-in-with-button'
                      onClick={() =>
                        this.signInWithIntuit(this.validateHostUrl())
                      }
                    >
                      <img
                        src={this.state.signInWithIntuitImg}
                        onMouseEnter={() => {
                          this.setState({
                            signInWithIntuitImg: signInWithIntuitHover,
                          });
                        }}
                        onMouseOut={() => {
                          this.setState({
                            signInWithIntuitImg: signInWithIntuit,
                          });
                        }}
                      />
                    </button>
                    {/* <button type='submit' className='button-background'>
                      Sign In with Intuit
                    </button> */}
                  </div>
                </div>
                <div className='makegood-contact'>
                  <p>Want to learn more or speak with someone at MakeGood?</p>
                  <div style={{ display: "flex" }}>
                    <div className='learm-more-app'>
                      <button
                        type='submit'
                        className='button-background'
                        onClick={() => {
                          window.location.href = "https://www.makegoodapp.com/";
                        }}
                      >
                        Learn More
                      </button>
                    </div>
                    <div className='book-demo'>
                      <button
                        type='submit'
                        className='button-background'
                        onClick={() => {
                          window.location.href =
                            "https://app.acuityscheduling.com/schedule.php?owner=28191594";
                        }}
                      >
                        Book Demo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QbLaunch;
