import React, { Component } from "react";
// import ClientsNav from "../../../components/Clients/ClientsNav";
import MGLogoIcon from "../../../assets/icons/MG-logo.png";
import PasswordHidden from "../../../assets/images/eye-off.png";
import PasswordVisible from "../../../assets/images/eye.png";
import { Cookies, withCookies } from "react-cookie";
import * as API from "../../../utils/api";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import { getIcon } from "../../../assets/icons/svg_icons_list.js";
import {
  validatePassword,
  validateSubDomain,
  errorMessage,
} from "../../../utils/helpers";
import $ from "jquery";

class CreateSubDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isReadyToSubmit: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
        isSubDomainError: false,
        subDomainErrorText: "",
      },
      hiddenPassword: true,
      hiddenConfirmPassword: true,
      input: {
        password: {
          value: "",
          errorMsg: "",
          hasError: false,
          animate: false,
        },
        confirmPassword: {
          value: "",
          errorMsg: "",
          hasError: false,
          animate: false,
        },
        domainName: {
          value: "",
          errorMsg: [""],
          hasError: false,
          animate: false,
        },
      },
    };
    this.spanRef = React.createRef();
  }

  componentDidMount() {
    //get initial dashboard details
    let checkCookie = this.props.getCookiesData("email");
    if (checkCookie == undefined || checkCookie.email == "") {
      this.props.history.push("/launch");
    }
  }

  backToLogin = () => {
    this.props.history.push("/launch");
  };

  spinnerHandler = (loadingState) => {
    if (loadingState) {
      $("#send-new-mail").replaceWith(
        '<div id = "spinner" class="spinner"></div>'
      );
    } else {
      $("#spinner").replaceWith(
        '<div id="send-new-mail" className="modal-actions double-btns"> </div>'
      );
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    this.props.handleStateChange(newPartialInput);
  }

  dateIsValid = (date) => {
    if (date && date != "") {
      return new Date(date) instanceof Date && !isNaN(new Date(date));
    } else {
      return false;
    }
  };

  validateForm = () => {
    let { password, confirmPassword, domainName } = this.state.input;
    let checkPassword = validatePassword(password.value);
    let checkConfirmPassword = validatePassword(confirmPassword.value);
    let checkDomainName = validateSubDomain(domainName.value);
    let flag = true;
    if (!checkPassword.flag) {
      flag = false;
      this.handleInputChange({
        password: {
          value: password.value,
          errorMsg: checkPassword.msg,
          hasError: true,
          animate: true,
        },
      });
    }
    if (!checkConfirmPassword.flag) {
      flag = false;
      this.handleInputChange({
        confirmPassword: {
          value: confirmPassword.value,
          errorMsg: checkConfirmPassword.msg,
          hasError: true,
          animate: true,
        },
      });
    } else {
      if (password.value !== confirmPassword.value) {
        flag = false;
        this.handleInputChange({
          confirmPassword: {
            value: confirmPassword.value,
            errorMsg: "Passwords do not match. Please confirm password.",
            hasError: true,
            animate: true,
          },
        });
      }
    }
    if (!checkDomainName.flag) {
      flag = false;
      this.handleInputChange({
        domainName: {
          value: domainName.value,
          errorMsg: checkDomainName.msg,
          hasError: true,
          animate: true,
        },
      });
    }
    return flag;
  };
  encodeString = (str) => {
    let mypass = str;
    for (let i = 0; i < 16; i++) {
      mypass = Buffer.from(mypass, "binary").toString("base64");
    }
    return mypass;
  };

  onSubmit = (event) => {
    event.preventDefault();
    let { history } = this.props;
    let cookies = new Cookies();
    let { password, confirmPassword, domainName } = this.state.input;
    if (this.validateForm()) {
      let Payload = {
        user_info: {
          email: this.props.getCookiesData("email"),
          password: password.value,
          confirm_password: confirmPassword.value,
        },
      };
      this.setState({ isLoading: true });
      API.createStandaloneOrg(Payload).then((data) => {
        console.log(data);
        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          data.status_code === 200
        ) {
          this.props.handleStateChange({
            password: password.value,
            organization_key: data.org_key,
          });
          this.props.setCookiesData("organization_key", data.org_key);
          let userCandidate = {
            email: this.props.getCookiesData("email"),
            organization_key: data.org_key,
            password: password.value,
          };
          API.setOauthSSOObj(userCandidate);
          API.ssoLogin(userCandidate).then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              if (!data.errors) {
                const user = {
                  first_name: data.first_name,
                  last_name: data.last_name,
                  user_id: data.id,
                  user_auth_token: data.auth_token,
                  admin: data.admin,
                  is_employee: data.is_employee,
                  super_admin: data.super_admin,
                  onboarding_complete: data.accepted_on ? true : false,
                  is_onboarding_completed: data.accepted_on ? true : false,
                  client_admin: data.client_admin,
                  verified: data.verified,
                  verified_at: data.verified_at,
                  email: data.email,
                  user_hash: data.user_hash,
                  intercom_id: data.intercom_id,
                  organization_key:
                    this.props.getCookiesData("organization_key"),
                };
                this.setState({
                  user_auth_token: data.auth_token,
                  user_id: data.id,
                  eula_accepted_date: this.dateIsValid(data.accepted_on),
                });
                if (this.dateIsValid(data.accepted_on)) {
                  this.props.setEULAtrue();
                }
                this.props.updateUserLogin(user);
                let domainPayload = {
                  sso_user_email: this.props.getCookiesData("email"),
                  org_url:
                    process.env.REACT_APP_STAGE === "production"
                      ? domainName.value + ".makegoodapp.com"
                      : domainName.value + ".accountpayonline.com",
                  organization_key:
                    this.props.getCookiesData("organization_key"),
                };
                this.spinnerHandler(this.state.isLoading);
                API.saveSubdomain(user, domainPayload).then((data) => {
                  if (
                    (typeof data != "undefined" || data != null) &&
                    data.status_code == 200
                  ) {
                    this.props.updateModalState(true, "SUCCESS", {
                      message: data.message,
                    });
                    cookies.remove("authUser", { path: "/", secure: true });
                    cookies.remove("isLoggedIn", { path: "/", secure: true });
                    cookies.remove("accessToken", {
                      path: "/",
                      secure: true,
                    });
                    cookies.remove("refreshToken", {
                      path: "/",
                      secure: true,
                    });
                    this.setState({ isLoading: false });
                    this.spinnerHandler(this.state.isLoading);
                    if (data.data) {
                      cookies.remove("email", {
                        path: "/",
                        secure: true,
                      });
                      cookies.remove("checkIsNewUser", {
                        path: "/",
                        secure: true,
                      });
                      let url = new URL(data.data.sudomain_name + "/login");

                      // Add parameters
                      url.searchParams.append("username", data.data.email);
                      url.searchParams.append(
                        "pk",
                        this.encodeString(password.value)
                      );
                      url.searchParams.append(
                        "org_key",
                        this.props.getCookiesData("organization_key")
                      );
                      history.push("/sso_dashboard");
                      this.props.setCookiesData("url", url);
                    }
                  } else {
                    this.setState({ isLoading: false });
                    this.setState((state) => ({
                      ...state,
                      error: {
                        hasAnyError: true,
                        statusCode: data
                          ? data.status ||
                            (data.message && data.message == "Access denied."
                              ? 401
                              : 500) ||
                            500
                          : 500,
                      },
                    }));
                    this.props.updateModalState(true, "ERROR", {
                      message: data.message,
                    });
                  }
                });
              } else {
                this.setState((state) => ({
                  ...state,
                  showFlash: true,
                  accessError: errorMessage,
                  errorMsg: data.errors,
                }));
              }
            } else {
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
            this.setState({ isLoading: false });
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
          this.setState({ isLoading: false });
        }
      });
    }
  };

  changeInputType = (type) => {
    let { hiddenPassword, hiddenConfirmPassword } = this.state;
    if (type === "hiddenPassword") {
      this.setState((state) => ({
        ...state,
        hiddenPassword: !hiddenPassword,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        hiddenConfirmPassword: !hiddenConfirmPassword,
      }));
    }
  };

  animateLabel = (type, value) => {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        [type]: {
          ...state.input[type],
          animate: value !== "",
        },
      },
    }));
  };

  render() {
    const { appName, user } = this.props;
    const { isLoading, error, input, hiddenPassword, hiddenConfirmPassword } =
      this.state;
    return (
      <div className="app-portal">
        {isLoading && <AppCanvasLoader />}
        <div
          className={`app-portal-canvas consumer-portal canvas-back`}
          style={this.props.backgroundStyle}
        >
          <div className="wrap sso-dashboard-cls manage-height he-hed">
            <div className="company-logo-center">
              <img className="companyLogoDiv-consumer-view" src={MGLogoIcon} />
            </div>
            <div className="user-details-content">
              <h4 className="user-details-heading">Create New Password</h4>
              <p>Set a new password for secure login.</p>
              <form autoComplete="off">
                <div className={`user-details-input-textField`}>
                  <div className="password-lock">
                    {getIcon("password-lock-icon")}
                  </div>
                  <div
                    className="hidden-password"
                    onClick={() => this.changeInputType("hiddenPassword")}
                  >
                    {hiddenPassword ? (
                      <img src={PasswordHidden} width="20" height="20" />
                    ) : (
                      <img src={PasswordVisible} width="20" height="20" />
                    )}
                  </div>
                  {input.password.animate && <label>Password</label>}
                  <input
                    id="new-user-password"
                    placeholder={!input.password.animate ? "Password" : ""}
                    type={hiddenPassword ? "password" : "text"}
                    value={input.password.value}
                    autoComplete="new-password"
                    onBlur={(event) =>
                      this.animateLabel("password", event.target.value)
                    }
                    onFocus={(event) =>
                      this.animateLabel("password", event.target.value)
                    }
                    onChange={(event) => {
                      let check = validatePassword(event.target.value);
                      this.handleInputChange({
                        password: {
                          value: event.target.value,
                          hasError: !check.flag,
                          errorMsg: check.msg,
                          animate: true,
                        },
                      });
                    }}
                  />
                  {input.password.hasError ? (
                    <p className="input-details-error-message">
                      {input.password.errorMsg}
                    </p>
                  ) : null}
                </div>
                <div className={`user-details-input-textField`}>
                  <div className="password-lock">
                    {getIcon("password-lock-icon")}
                  </div>
                  <div
                    className="hidden-password"
                    onClick={() =>
                      this.changeInputType("hiddenConfirmPassword")
                    }
                  >
                    {hiddenConfirmPassword ? (
                      <img src={PasswordHidden} width="20" height="20" />
                    ) : (
                      <img src={PasswordVisible} width="20" height="20" />
                    )}
                  </div>
                  {input.confirmPassword.animate && (
                    <label>Confirm Password</label>
                  )}
                  <input
                    id="confirm-new-user-password"
                    placeholder={
                      !input.confirmPassword.animate ? "Confirm Password" : ""
                    }
                    type={hiddenConfirmPassword ? "password" : "text"}
                    value={input.confirmPassword.value}
                    onBlur={(event) =>
                      this.animateLabel("confirmPassword", event.target.value)
                    }
                    onFocus={(event) =>
                      this.animateLabel("confirmPassword", event.target.value)
                    }
                    onChange={(event) => {
                      let check = validatePassword(event.target.value);
                      if (check.flag) {
                        this.handleInputChange({
                          confirmPassword: {
                            value: event.target.value,
                            hasError: !check.flag,
                            errorMsg: check.msg,
                            animate: true,
                          },
                        });
                      } else {
                        this.handleInputChange({
                          confirmPassword: {
                            value: event.target.value,
                            errorMsg:
                              "Passwords do not match. Please confirm password.",
                            hasError: true,
                            animate: true,
                          },
                        });
                      }
                    }}
                  />
                  {input.confirmPassword.hasError ? (
                    <p className="input-details-error-message">
                      {input.confirmPassword.errorMsg}
                    </p>
                  ) : null}
                </div>
                <div>
                  <p className="domain-label-cls">
                    Enter the web domain name of your choice here
                  </p>
                  <p>
                    This will complete the custom web domain that your
                    organization uses to log in. Customers will also see this
                    domain when they access your company-branded Billing and
                    Payment Portal.{" "}
                  </p>
                  <div className="user-domainName-container">
                    <div className="user-domainName">
                      {getIcon("network-domain-icon")}
                    </div>
                    {input.domainName.animate && <label>Domain Name</label>}
                    <input
                      className="multi-input-field-input sub-domain-input mbwt"
                      id="new-user-domainName"
                      placeholder={
                        !input.domainName.animate ? "Domain Name" : ""
                      }
                      type="text"
                      value={input.domainName.value}
                      onBlur={(event) =>
                        this.animateLabel("domainName", event.target.value)
                      }
                      onFocus={(event) =>
                        this.animateLabel("domainName", event.target.value)
                      }
                      onChange={(event) => {
                        {
                          let check = validateSubDomain(event.target.value);
                          this.handleInputChange({
                            domainName: {
                              value: event.target.value,
                              hasError: !check.flag,
                              errorMsg: check.msg,
                              animate: true,
                            },
                          });
                        }
                      }}
                    />
                    <p className="mbdomain">
                      {process.env.REACT_APP_STAGE === "production"
                        ? ".makegoodapp.com"
                        : ".accountpayonline.com"}
                    </p>
                  </div>
                  {input.domainName.hasError
                    ? input.domainName.errorMsg.map((msg, index) => (
                        <p key={index} className="input-error-message">
                          {msg}
                        </p>
                      ))
                    : null}
                </div>
                <div className={"submit-user-details"}>
                  <button
                    type="submit"
                    className={`${
                      this.state.isReadyToSubmit
                        ? "button-background1"
                        : "button-background1"
                    }`}
                    onClick={(event) => this.onSubmit(event)}
                  >
                    Submit
                  </button>
                </div>
              </form>

              <div className="login-text">
                <p>
                  Back to <span onClick={() => this.backToLogin()}>Log In</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateSubDomain;
