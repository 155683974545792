import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import * as API from "../utils/api";
import companyData from "../assets/styles/theme.json";
import MakeGoodLogo from "../../src/assets/icons/MG-logo.png";

class Logo extends Component {
  static propTypes = {
    circular: PropTypes.bool,
    linkPath: PropTypes.string,
    linkTitle: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      company_name: companyData.name, //appName
      company_logo2: "",
      logoUrl: "",
    };
  }

  checkAdminPortal() {
    if (this.porps.hasOwnProperty("adminPortal")) {
      if (this.porps.adminPortal) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          company_logo2: response.data.logo_url,
        });
      })
      .catch(() => {});
  }

  componentDidMount() {
    this.getLogoColorsForPayment();
    this.setState({
      ...this.state,
      logoUrl: this.props.logoUrl,
    });
  }

  componentDidUpdate() {
    if (this.state.logoUrl !== this.props.logoUrl) {
      this.setState({
        ...this.state,
        logoUrl: this.props.logoUrl,
      });
    }
  }

  checkWidthAsMediaSize() {
    let x = window.matchMedia("(max-width: 600px)");
    if (x.matches) {
      return "60px";
    } else {
      return "75px";
    }
  }

  render() {
    const { accountHolder, hideNav, isDynamicLogo, footerLogo, userAgreement } =
      this.props;
    console.log(this.props, "--------------");
    const { logoUrl, company_logo2 } = this.state;

    return (
      <div>
        <div
          className="companyLogoDiv"
          style={
            footerLogo
              ? { margin: "0 0 0 0", width: 40, float: "left" }
              : {
                  margin: "0 0 0 0",
                  height: "35px",
                }
          }
        >
          <img
            style={footerLogo ? { width: 40, height: 36 } : { height: "35px" }}
            src={MakeGoodLogo}
          />
        </div>
      </div>
    );
  }
}

export default Logo;
